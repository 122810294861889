import { template as template_a6e6750385114a2eb5fea51d9a86b4ca } from "@ember/template-compiler";
const FKText = template_a6e6750385114a2eb5fea51d9a86b4ca(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_721585d9965f474b925d6ab79f66a4ff } from "@ember/template-compiler";
const FKLabel = template_721585d9965f474b925d6ab79f66a4ff(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_aefa8997f13f46adb3bb044bd53c6f62 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_aefa8997f13f46adb3bb044bd53c6f62(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;

import { template as template_77c5ceaa771546a792cee28460da5b21 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_77c5ceaa771546a792cee28460da5b21(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
